@use 'base/variables' as v;
@use 'base/mixins' as m;

html {
    font-size: 62.5%;
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}
body {
    font-family: v.$fuente_principal;
    font-size: 1.6rem;
    line-height: 1.8;
}
p {
    color: v.$negro;
    font-size: 2rem;
}
.contenedor {
    width: 95%;
    max-width: 140rem;
    margin: 0 auto;

    &.contenedor-tabla {
        max-width: 180rem;
    }
}

a {
    text-decoration: none;
}

img,
picture {
    width: 100%;
    display: block;
}

h1, h2, h3 {
    font-family: v.$fuente_principal;
    margin: v.$separacion / 2 0;
    font-weight: 300;
    text-align: center;
    
}

h1 {
    font-size: 3.8rem;
}
h2 {
    font-size: 3.4rem;
}
h3 {
    font-size: 3rem;
}
h4 {
    font-size: 2.6rem;
}