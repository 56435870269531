@use 'base/variables' as v;
@use 'base/mixins' as m;

.boton-amarillo {
    @include m.boton(v.$amarillo, inline-block);
}

.boton-amarillo-block {
    @include m.boton(v.$amarillo, block);
}

.boton-verde {
    @include m.boton(v.$verde, inline-block);
}

.boton-rojo {
    @include m.boton(v.$rojo, block);
}

.boton-rojo-block {
    @include m.boton(v.$rojo, block);
    width: 100%;
}
.boton-verde-block {
    @include m.boton(v.$verde, block);
}

.boton-rojo-block-chico {
    @include m.boton(v.$rojo, inline-block);
}
.boton-amarillo-block-chico {
    @include m.boton(v.$amarillo, inline-block); 
}

.botones-chicos {
    @include m.grid(2,0.5rem);
}

.titulo-boton {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .invisible {
        width: 10rem;
    }

    .select-input {
        display: flex;
        gap: 1rem;
        align-items: baseline;
    }

    &.baseline {
        align-items: baseline;
    }
}

.w-100 {
    width: 100%;
}