@use 'base/variables' as v;
@use 'base/mixins' as m;

table.propiedades {
    margin-top: 4rem;
    width: 100%;
    border-spacing: 0;

    thead {
        background-color: v.$verde;

        th {
            color: v.$blanco;
            padding: 2rem;
        }
    }

    td {
        text-align: center;
        background-color: v.$gris;
    }

    .imagen-tabla {
        width: 10rem;
    }
}

.acciones {
    width: 1rem;
}

.alerta {
    padding: .5rem;
    text-align: center;
    color: v.$blanco;
    text-transform: uppercase;
    font-weight: bold;
    margin: 1rem 0;

    &.error {
        background-color: v.$rojo;
    }

    &.exito {
        background-color: v.$verde;
    }
}