@use 'base/variables' as v;
@use 'base/mixins' as m;


.valido {
    color: v.$verde;
}

.invalido {
    color: v.$rojo;
}

.enlace {
    padding-left: 0.5rem;
    padding-right:0.5rem;
}


.chequeo-perfil {
    font-weight: bold;
    padding-left: 1rem;
    padding-bottom: 1rem;
}

.no-mostrar {
    display: none;
}



.formulario {
    
    margin-bottom: 5rem;

    legend {
        font-size: 2rem;
        color: v.$grisOscuro;
    }

    label {
        text-transform: uppercase;
        font-weight: bold;
        display: block;
    }

    input:not([type="submit"]),
    textarea,
    select {
        padding: 1rem;
        display: block;
        width: 100%;
        background-color: v.$blanco;
        border: 1px solid v.$gris;
        border-radius: 1rem;
        margin-bottom: 2rem;

        &.repetidos {
            background-color: v.$gris;
        }
    }

    textarea {
        height: 20rem;
    }

    &.formulario-perfil {
        max-width: 50rem;
        margin: auto;
        margin-bottom: 5rem;

        p {
            text-align: center;
            font-size: 1.5rem;
            font-weight: bold;
            padding: 0.5rem;
        }
    }

    fieldset {
        margin-top: 2rem;
    }

    .checkbox {
        display: flex;
        margin-bottom: 2rem;
        label {
            text-align: center;
            font-weight: normal;
            padding-right: 5rem;
            padding-top: 2rem;
        }
        input {
            
            text-align: center;           
        }
    }
}

.formulario-abiertas {

    .contenedor-form-abiertas {
        @include m.grid(4,2);
    }

    max-width: 180rem;
    margin: auto;
    margin-bottom: 5rem;

}








