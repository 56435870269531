/** Media Queries **/

@use 'base/variables' as v;

@mixin telefono {
    @media (min-width: 480px) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: 768px) {
        @content;
    }
}
  
@mixin desktop {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin grid($columnas, $espaciado ) {
    display: grid;
    gap: $espaciado;
    grid-template-columns: repeat($columnas, 1fr);
}

@mixin boton($color, $display) {
    background-color: $color;
    color: v.$blanco;
    text-decoration: none;
    padding: 1rem 3rem;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: $display;
    border: none;
    &:hover {
        background-color: darken($color, 5);
        cursor: pointer;
    }

}    
