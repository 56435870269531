@use 'base/variables' as v;
@use 'base/mixins' as m;

.barra {
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
    @include m.tablet {
        flex-direction: row;
        justify-content: space-between;

    }
}

.logo {
    margin: 0;
    font-size: 2.5rem;
    font-weight: bold;
    color: v.$blanco;
    
    span {
        font-weight: normal;
        font-family: v.$fuente_principal;
    }
}

.navegacion {
    @include m.grid(2, 0.3rem);

    @include m.tablet {
        display: flex;
        align-items: center;
    }

    a{
        color: v.$blanco;
        text-decoration: none;
        font-size: 2.2rem;
        display: block;
        text-align: center;
        &:hover {
            color: v.$verde;
            transition: ease .2s all;
            }

        @include m.tablet {
            margin-right: 2rem;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}