@use 'base/variables' as v;
@use 'base/mixins' as m;

.nuevo-candidato-obligatorios-grid {
    @include m.grid(3,2rem);
    
    .obligatorio:first-of-type {
        grid-column: 1 / 4;
    }
    //.obligatorio:last-of-type {
    //    grid-column: 2 / 4;
    //}
}
.nuevo-candidato-ultima-relacion-laboral-grid {
    @include m.grid(2, 2rem);
}

.contacto-grid {
    @include m.grid(2,2rem);
}

.entrevista-grid {
    @include m.grid(2,2rem);
}

.definicion-grid {
    @include m.grid(2,2rem);
}

.resolucion-grid {
    @include m.grid(2,2rem)
}

