@use 'base/variables' as v;
@use 'base/mixins' as m;

.contenedor-descripcion {
    @include m.tablet {
        @include m.grid(3,2rem);
        margin: 4rem 0 4rem 0;
    }
    display: flex;
    flex-direction: column;

    h2 {
        background-color: lighten(v.$grisOscuro,25);
        color: v.$blanco;
        border-radius: 1rem;
    }
}

.links {
    grid-row: 1 / 4;
    grid-column: 1 / 2;
    padding: 0 2rem 0 2rem;
}

.datos {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    padding: 0 2rem 0 2rem;

    .contenedor-datos {
        @include m.tablet {
            @include m.grid(4,2rem)
        }
    }
}

.fechas {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    padding: 0 2rem 0 2rem;

    .contenedor-fechas {
        @include m.tablet {
            @include m.grid(5,2rem)
        }
    }
}

.comentarios {
    grid-column: 2 / 4;
    grid-row: 3 / 4;
    padding: 0 2rem 0 2rem;
}

.resoluciones {
    grid-column: 1 / 4;
    grid-row: 4 / 5;
    padding: 0 2rem 0 2rem;

    .contenedor-resoluciones {
        display: flex;
    }
}

.link {
    h3 {
        margin: 1rem;
        font-size: 2rem;
        text-align: left;
    }

    a {
        font-size: 2rem;
        padding: 0.5rem;
    }

    &:last-of-type {
        margin-bottom: 2rem;
    }
}

.fechas, .datos {
    line-height: 1.15;

    h3 {
        margin: 1rem;
        font-size: 2rem;
    }

    h3,p {
        text-align: center;
    }
}