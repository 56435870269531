@use 'base/variables' as v;
@use 'base/mixins' as m;

.actualizar-eliminar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.botones-perfil {
    display: flex;
    gap: 1rem;
}

.no-mostrar {
    display: none;
}