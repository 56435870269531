@use 'base/variables' as v;
@use 'base/mixins' as m;

.tab {
    display: inline-block;
    border: 1px solid #ccc;
    background-color: v.$gris;

    .tab-boton {
        background-color: inherit;
        float: left;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        &:hover {
            background-color: v.$verde;
            color: v.$blanco;
        }
        &.active {
            background-color: v.$verde;
            color: v.$blanco;
        }
      }
  }

.no-mostrar-tab {
    display: none;
}

