@use 'base/variables' as v;
@use 'base/mixins' as m;


@include m.tablet {
    .contenedor-busquedas {
        @include m.grid(2, 2rem);
    }
}


.custom {
    background-color: lighten(v.$gris, 5);
    border: 1px solid darken(v.$gris, 10);
    margin-bottom: 2rem;

    &.custom-last {
        grid-column: 1 / 3;
    }


    a {
        color: v.$grisOscuro;
        text-decoration: none;
        font-size: 2.2rem;
        display: block;
        text-align: center;
        padding: 2rem 0 2rem 0;

        &:hover {
            color: v.$blanco;
            background-color: v.$verde;
            transition: ease .3s all;
        }
        &.bg-celeste {
            color: v.$grisOscuro;
            background-color: darken(v.$gris, 10);
            &:hover {
                color: v.$blanco;
                background-color: v.$celeste;
                transition: ease .3s all;
            }
        }
}}

.linea {
    margin: 2rem;
}