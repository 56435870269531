@use 'base/variables' as v;
@use 'base/mixins' as m;

.footer {
    padding: 2.5rem 0;
    background-color: v.$grisOscuro;


    .contenido-footer {
        padding-top: v.$separacion / 2;
    }

    .copyright {
        color: v.$blanco;
        text-align: center;
        margin: 0;
    }
}

.fixed {
    position: absolute;
    width:100%;
    bottom: 0;
}